import React, { useEffect, useState } from "react";
import { Container, Table, Button, Modal, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewCustormer,
  deleteCustormerData,
  fetchAllCustormers,
  updateCustormerData,
} from "../redux/actions/CustormerAction";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

function CustomerManagement() {
  const dispatch = useDispatch();
  const { customers, loading } = useSelector((state) => state.customers);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    company_name: "",
    short_name: "",
    address: "",
    email: "",
    contact_number: "",
    registration_number: "",
  });
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    dispatch(fetchAllCustormers());
  }, [dispatch, showModal, selectedCustomer]);

  const handleShowModal = () => {
    if (selectedCustomer) {
      setFormData({
        company_name: selectedCustomer.company_name || "",
        short_name: selectedCustomer.short_name || "",
        address: selectedCustomer.address || "",
        email: selectedCustomer.email || "",
        contact_number: selectedCustomer.contact_number || "",
        registration_number: selectedCustomer.registration_number || "",
      });
    }
    // Clear any previous errors when opening the modal
    setErrors({});
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormData({
      company_name: "",
      short_name: "",
      address: "",
      email: "",
      contact_number: "",
      registration_number: "",
    });
    setErrors({});
    setSelectedCustomer(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Update form data
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    // Clear error for the specific field once the user starts editing it
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  // Simple validation function for the form fields
  const validateForm = () => {
    const newErrors = {};

    if (!formData.company_name?.trim()) {
      newErrors.company_name = "Company name is required";
    }
    if (!formData.short_name?.trim()) {
      newErrors.short_name = "Short name is required";
    }
    if (!formData.address?.trim()) {
      newErrors.address = "Address is required";
    }
    if (!formData.email?.trim()) {
      newErrors.email = "Email is required";
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        newErrors.email = "Please enter a valid email address";
      }
    }
    if (!formData.contact_number?.trim()) {
      newErrors.contact_number = "Contact number is required";
    }
    if (!formData.registration_number) {
      newErrors.registration_number = "Registration number is required";
    }

    return newErrors;
  };


  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Validate the form inputs
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    if (selectedCustomer) {
      // Update existing customer
      dispatch(updateCustormerData(selectedCustomer.id, formData))
        .then(() => {
          dispatch(fetchAllCustormers()); // Fetch updated list
        });
    } else {
      // Add new customer
      dispatch(createNewCustormer(formData))
        .then(() => {
          dispatch(fetchAllCustormers()); // Fetch updated list
        });
    }

    handleCloseModal();
  };


  const handleDeleteRow = () => {
    if (!selectedCustomer) {
      alert("Please select a customer to delete.");
      return;
    }

    const confirmDelete = window.confirm("Are you sure you want to delete this customer?");
    if (confirmDelete) {
      dispatch(deleteCustormerData(selectedCustomer.id))
        .then(() => {
          dispatch(fetchAllCustormers()); // Fetch updated list
          setSelectedCustomer(null); // Reset selection
        })
        .catch(error => console.error('Error deleting customer:', error));
    }
  };



  return (
    <Container className="mt-4">
      <h2 className="mb-4 text-center">Manage Companies</h2>
      <Button variant="success" className="mb-3" onClick={() => handleShowModal()}>
        Add Company
      </Button>
      <Button
        variant="primary"
        className="mb-3 mx-1"
        onClick={() =>
          selectedCustomer ? handleShowModal() : alert("Please select a row")
        }
      >
        Edit
      </Button>
      <Button variant="danger" className="mb-3" onClick={() => handleDeleteRow()}>
        Delete
      </Button>
      <div className="card">
        <DataTable
          value={customers}
          selectionMode="single"
          selection={selectedCustomer}
          onSelectionChange={(e) => setSelectedCustomer(e.value)}
          dataKey="id"
          metaKeySelection={false}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column field="company_name" header="Company Name"></Column>
          <Column field="short_name" header="Short Name"></Column>
          <Column
            field="registration_number"
            header="Company Registration Number"
          ></Column>
          <Column field="contact_number" header="Contact Number"></Column>
          <Column field="email" header="Email"></Column>
          <Column field="address" header="Address"></Column>
        </DataTable>
      </div>

      {/* Modal for Add/Edit */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Form onSubmit={handleFormSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              {selectedCustomer ? "Edit Customer" : "Add Customer"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formCompanyName">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                name="company_name"
                value={formData.company_name}
                onChange={handleInputChange}
                placeholder="Enter company name"
                isInvalid={!!errors.company_name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.company_name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formShortName">
              <Form.Label>Short Name</Form.Label>
              <Form.Control
                type="text"
                name="short_name"
                value={formData.short_name}
                onChange={handleInputChange}
                placeholder="Enter short name"
                isInvalid={!!errors.short_name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.short_name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                placeholder="Enter address"
                isInvalid={!!errors.address}
              />
              <Form.Control.Feedback type="invalid">
                {errors.address}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Enter email"
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formContactNumber">
              <Form.Label>Contact Number</Form.Label>
              <Form.Control
                type="text"
                name="contact_number"
                value={formData.contact_number}
                onChange={handleInputChange}
                placeholder="Enter contact number"
                isInvalid={!!errors.contact_number}
              />
              <Form.Control.Feedback type="invalid">
                {errors.contact_number}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formRegistrationNumber">
              <Form.Label>Registration Number</Form.Label>
              <Form.Control
                type="text"
                name="registration_number"
                value={formData.registration_number}
                onChange={handleInputChange}
                placeholder="Enter registration number"
                isInvalid={!!errors.registration_number}
              />
              <Form.Control.Feedback type="invalid">
                {errors.registration_number}
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button variant="success" type="submit">
              {selectedCustomer ? "Update" : "Save"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
}

export default CustomerManagement;
