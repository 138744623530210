import Login from '../../pages/Login';
import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_REGISTER_FAILURE,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_LOGIN_FAILURE,
    USER_ROLES_REQUEST, USER_ROLES_SUCCESS, USER_ROLES_FAILURE, USERS_BY_ROLES_FAILURE, USERS_BY_ROLES_SUCCESS, USERS_BY_ROLES_REQUEST,
    FETCH_ALL_REGISTERD_USERS_REQUEST,
    FETCH_ALL_REGISTERD_USERS_SUCCESS,
    FETCH_ALL_REGISTERD_USERS_FAILURE,
    USER_UPDATE_REQUEST,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAILURE
} from '../actions/userAction';

const initialState = {
    loading: false,
    user: {},
    users: [],
    userRoles: [],
    userName: localStorage.getItem('loggedUserName') || '',
    token: localStorage.getItem('AuthToken') || null,
    auth: localStorage.getItem('auth') || false,
    role: localStorage.getItem('user_role') || false,
    userid: localStorage.getItem('user_id') || false,
    error: '',
    userRegisterd: false,
    usersByUserRole: {}
};

const userReduser = (state = initialState, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
        case USER_REGISTER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case USER_LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload.user,
                auth: true,
                token: action.payload.access,
                error: ''
            };
        case USER_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload,
                error: '',
                userRegisterd: true
            };

        case USER_LOGIN_FAILURE:
        case USER_REGISTER_FAILURE:
            return {
                ...state,
                loading: false,
                user: {},
                error: action.payload,
            }

        case USER_ROLES_REQUEST:
            return {
                ...state,
                loading: true,

            }

        case USER_ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
                userRoles: action.payload,
            }

        case USERS_BY_ROLES_REQUEST:
            return {
                ...state,
                loading: true,

            }

        case USERS_BY_ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
                usersByUserRole: action.payload,

            }

        case USER_LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                usersByUserRole: [],
                error: action.payload

            }

        case FETCH_ALL_REGISTERD_USERS_REQUEST:
            return {
                ...state,
                loading: false,
                users: [],
                error: action.payload

            }

        case FETCH_ALL_REGISTERD_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.payload,
                error: action.payload

            }

        case FETCH_ALL_REGISTERD_USERS_FAILURE:
            return {
                ...state,
                loading: false,
                users: [],
                error: action.payload

            }

        case USER_UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
                users: [],
                error: action.payload

            }

        case USER_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                users: [],
                error: action.payload

            }

        case USER_UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload

            }


        default:
            return state;
    }
};

export default userReduser;

