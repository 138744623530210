import React, { useEffect, useState } from "react";
import { Container, Button, Modal, Form } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCustormers, updateCustormerData, createNewCustormer } from "../redux/actions/CustormerAction";
import { deleteUserByUserId, fetchAllUserRoles, fetchAllUsersRegisterd, saveNewUser, updateUserByUserId } from "../redux/actions/userAction";
import { MultiSelect } from "primereact/multiselect";
import CreatableSelect from "react-select/creatable";
import Loder from "../component/Loder";

function UsersManagement() {
  const dispatch = useDispatch();

  // Redux selectors
  const { users = [], loading: usersLoading } = useSelector((state) => state.users || {});
  const { userRoles = [], loading: userRolesLoading } = useSelector((state) => state.userRoles || {});
  const { customers = [], loading: customersLoading } = useSelector((state) => state.customers || {});
  const { user = {}, loading: userLoading, error: userError } = useSelector((state) => state.user || {});

  // Local states
  const [formattedUserRoles, setFormattedUserRoles] = useState([]);
  const [updatedUserListWithRoleName, setUpdatedUserListWithRoleName] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    address: "",
    phone_number: "",
    user_role: "",
    password: "",
    confirmPassword: "",
    company_registration_id: [],
  });

  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({});

  // Fetch data
  useEffect(() => {
    dispatch(fetchAllUsersRegisterd());
    dispatch(fetchAllUserRoles());
    dispatch(fetchAllCustormers());
  }, [dispatch]);


  useEffect(() => {
    if (userRoles.length && customers.length) {
      formatAllDataForTablesAndDropDowns();
    }
  }, [users, userRoles, customers]);

  const formatAllDataForTablesAndDropDowns = () => {

    const userCompanies = [];

    const formattedRoles = userRoles.map((role) => ({
      value: role.id,
      label: role.name,
      description: role.description,
    }));
    setFormattedUserRoles(formattedRoles);

    if (users.length) {
      const updatedUsers = users.map((user) => ({
        ...user,
        user_role: formattedRoles.find((role) => role.value === user.user_role)?.label || "Unknown Role",
        companies: user.company_registration_id.map((comId) => {
          const company = customers.find((company) => company.id === comId);
          return company ? company.company_name : null;
        }).filter(Boolean).join(", "),
      }));

      setUpdatedUserListWithRoleName(updatedUsers);

    }

    const formattedCompanies = customers.map((company) => ({
      value: company.id,
      label: company.company_name,
    }));
    setCompanies(formattedCompanies);
  };



  // Show modal for add/edit
  const handleShowModal = (user = null) => {
    setSelectedUser(user);
    setFormData(
      user || {
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        address: "",
        phone_number: "",
        user_role: "",
        company_registration_id: [],
      }
    );
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedUser(null);
    setFormData({
      first_name: "",
      last_name: "",
      username: "",
      email: "",
      address: "",
      phone_number: "",
      user_role: "",
      company_registration_id: [],
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = () => {
    let errors = {};

    if (!formData.first_name.trim()) errors.first_name = "First name is required";
    if (!formData.last_name.trim()) errors.last_name = "Last name is required";
    if (!formData.username.trim()) errors.username = "Username is required";
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Invalid email format";
    }
    if (!formData.address.trim()) errors.address = "Address is required";
    if (!formData.phone_number.trim()) {
      errors.phone_number = "Phone number is required";
    } else if (!/^\d{10,15}$/.test(formData.phone_number)) {
      errors.phone_number = "Invalid phone number";
    }

    if (!selectedUser) {
      if (!formData.password) errors.password = "Password is required";
      if (!formData.confirmPassword) errors.confirmPassword = "Confirm Password is required";
      if (formData.password !== formData.confirmPassword) {
        errors.confirmPassword = "Passwords do not match";
      }
    }

    if (!selectedCompanies.length) errors.company = "Please select at least one company";
    if (!formData.user_role) errors.user_role = "Please select a role";

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log('Errors:', errors);

    if (!validateForm()) return;

    if (!selectedUser && formData.password !== formData.confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    const newFormData = {
      ...formData,
      user_role: formattedUserRoles.find((ur) => ur.label === formData.user_role)?.value || formData.user_role,
      company_registration_id: selectedCompanies,
    };

    console.log("Final newFormData:", newFormData); // Debugging

    if (selectedUser) {
      delete newFormData.password;
      delete newFormData.confirmPassword;
      console.log('newFormData', newFormData);
      dispatch(updateUserByUserId(selectedUser.id, newFormData))
      .then(() => {
        dispatch(fetchAllUsersRegisterd());
        formatAllDataForTablesAndDropDowns();
        handleCloseModal();
      })
      .catch((error) => console.error("Error saving user:", error));
    } else {
      try {
        dispatch(saveNewUser(newFormData)).then(() => {
          window.location.reload();
        });
      } catch (error) {
        console.log(userError);
      }
    }

    handleCloseModal();
  };


  const handleDeleteUser = () => {
    if (selectedUser) {
      alert(`Deleted user: ${selectedUser.username}`);
      dispatch(deleteUserByUserId(selectedUser.id))
      setSelectedUser(null);
    } else {
      alert("Please select a user to delete.");
    }
  };

  // Conditional rendering for table and modal
  if (usersLoading || userRolesLoading || customersLoading) {
    return <div className="d-flex justify-content-center align-items-center mt-5"><Loder /></div>;
  }

  const handleSelect = (e) => {
    if (e) {
      setSelectedUser(e.value);
      setSelectedCompanies(e.value.company_registration_id);
    }

  }


  return (
    <Container className="mt-4">
      <h2 className="mb-4 text-center">Manage Users</h2>

      <div className="mb-3">
        <Button variant="success" onClick={() => handleShowModal()}>
          Add User
        </Button>
        <Button
          variant="primary"
          className="mx-2"
          onClick={() => (selectedUser ? handleShowModal(selectedUser) : alert("Please select a user to edit."))}
        >
          Edit User
        </Button>
        <Button variant="danger" onClick={handleDeleteUser}>
          Delete User
        </Button>
      </div>

      <div className="card">
        <DataTable
          value={updatedUserListWithRoleName}
          selectionMode="single"
          selection={selectedUser}
          onSelectionChange={handleSelect}
          dataKey="id"
          metaKeySelection={false}
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column field="id" header="ID"></Column>
          <Column field="first_name" header="First Name"></Column>
          <Column field="last_name" header="Last Name"></Column>
          <Column field="username" header="Username"></Column>
          <Column field="email" header="Email"></Column>
          <Column field="phone_number" header="Phone Number"></Column>
          <Column field="address" header="Address"></Column>
          <Column field="user_role" header="User Role"></Column>
          <Column field="companies" header="Companies"></Column>
        </DataTable>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Form onSubmit={handleFormSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedUser ? "Edit User" : "Add User"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
                placeholder="Enter first name"
                isInvalid={!!errors.first_name}
              />
              <Form.Control.Feedback type="invalid">{errors.first_name}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
                placeholder="Enter last name"
                isInvalid={!!errors.last_name}
              />
              <Form.Control.Feedback type="invalid">{errors.last_name}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
                placeholder="Enter username"
                isInvalid={!!errors.username}
              />
              <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Enter email"
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleInputChange}
                placeholder="Enter phone number"
                isInvalid={!!errors.phone_number}
              />
              <Form.Control.Feedback type="invalid">{errors.phone_number}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Home Town</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                placeholder="Enter phone number"
                isInvalid={!!errors.address}
              />
              <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
            </Form.Group>

            {!selectedUser && (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    placeholder="Enter password"
                    isInvalid={!!errors.password}
                  />
                  <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                    placeholder="Confirm password"
                    isInvalid={!!errors.confirmPassword}
                  />
                  <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>
                </Form.Group>
              </>
            )}

            <Form.Group className="mb-3">
              <Form.Label>Company</Form.Label>
              <CreatableSelect
                isMulti
                value={selectedCompanies.map((companyId) => ({
                  value: companyId,
                  label: companies.find((c) => c.value === companyId)?.label || "",
                }))}
                options={companies}
                onChange={(selectedOptions) => {
                  const selectedIds = selectedOptions.map((option) => option.value);
                  setSelectedCompanies(selectedIds);
                }}
                isClearable
                placeholder="Select companies"
              />
              {errors.company && <div className="text-danger">{errors.company}</div>}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Role</Form.Label>
              <CreatableSelect
                isClearable
                options={formattedUserRoles}
                value={formattedUserRoles.find((opt) => opt.label === formData.user_role)}
                onChange={(option) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    user_role: option ? option.value : "",
                  }), console.log(formData)

                  )
                }
              />
              {errors.user_role && <div className="text-danger">{errors.user_role}</div>}
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button variant="success" type="submit">
              {selectedUser ? "Update User" : "Save User"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
}

export default UsersManagement;
