import axios from "axios";

export const FETCH_CUSTORMERS_REQUEST = 'FETCH_CUSTORMERS_REQUEST';
export const FETCH_CUSTORMERS_SUCCESS = 'FETCH_CUSTORMERS_SUCCESS';
export const FETCH_CUSTORMERS_FAILURE = 'FETCH_CUSTORMERS_FAILURE';

export const CREATE_CUSTORMERS_REQUEST = 'CREATE_CUSTORMERS_REQUEST';
export const CREATE_CUSTORMERS_SUCCESS = 'CREATE_CUSTORMERS_SUCCESS';
export const CREATE_CUSTORMERS_FAILURE = 'CREATE_CUSTORMERS_FAILURE';

export const UPDATE_CUSTORMERS_REQUEST = 'UPDATE_CUSTORMERS_REQUEST';
export const UPDATE_CUSTORMERS_SUCCESS = 'UPDATE_CUSTORMERS_SUCCESS';
export const UPDATE_CUSTORMERS_FAILURE = 'UPDATE_CUSTORMERS_FAILURE';

export const DELETE_CUSTORMERS_REQUEST = 'DELETE_CUSTORMERS_REQUEST';
export const DELETE_CUSTORMERS_SUCCESS = 'DELETE_CUSTORMERS_SUCCESS';
export const DELETE_CUSTORMERS_FAILURE = 'DELETE_CUSTORMERS_FAILURE';

export const fetchCustormersRequest = () => {
    return {
        type: FETCH_CUSTORMERS_REQUEST,
    }
}

export const fetchCustormersSuccess = (custormers) => {
    return {
        type: FETCH_CUSTORMERS_SUCCESS,
        payload: custormers,
    }
}

export const fetchCustormersFailure = (error) => {
    return {
        type: FETCH_CUSTORMERS_FAILURE,
        payload: error,
    }
}

export const createCustormersRequest = () => {
    return {
        type: CREATE_CUSTORMERS_REQUEST,
    }
}

export const createCustormersSuccess = (customer) => {
    return {
        type: CREATE_CUSTORMERS_SUCCESS,
        payload: customer
    }
}

export const createCustormersFailure = (error) => {
    return {
        type: CREATE_CUSTORMERS_FAILURE,
        payload: error,
    }
}

export const updateCustormerRequest = () => {
    return {
        type: UPDATE_CUSTORMERS_REQUEST,
    }
}

export const updateCustormersSuccess = (updatedCompanie) => {
    return {
        type: UPDATE_CUSTORMERS_SUCCESS,
        payload: updatedCompanie
    }
}

export const updateCustormersFailure = (error) => {
    return {
        type: UPDATE_CUSTORMERS_FAILURE,
        payload: error
    }
}

export const deleteCustormerRequest = () => {
    return {
        type: DELETE_CUSTORMERS_REQUEST,
    }
}

export const deleteCustormersSuccess = (pk) => {
    return {
        type: DELETE_CUSTORMERS_SUCCESS,

    }
}

export const deleteCustormersFailure = (error) => {
    return {
        type: DELETE_CUSTORMERS_FAILURE,
        payload: error
    }
}

export const fetchAllCustormers = () => {
    return async (dispatch) => {
        dispatch(fetchCustormersRequest);
        try {
            const responce = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/companies/`);
            dispatch(fetchCustormersSuccess(responce.data))
        } catch (error) {
            dispatch(fetchCustormersFailure(error))
            console.log(error);
        }
    }
}

export const createNewCustormer = (newCompanie) => {
    return async (dispatch) => {
        dispatch(createCustormersRequest);
        try {
            const responce = axios.post(`${process.env.REACT_APP_BACKEND_URL}/companies/create/`, newCompanie);
            dispatch(createCustormersSuccess(responce.data))
        } catch (error) {
            console.log("redux responce", error);
            dispatch(createCustormersFailure(error))
        }
    }
}

export const updateCustormerData = (pk, updatedCompanie) => {
    return async (dispatch) => {
        dispatch(updateCustormerRequest);
        try {
            const responce = axios.put(`${process.env.REACT_APP_BACKEND_URL}/companies/update/${pk}/`, updatedCompanie);
            dispatch(updateCustormersSuccess(responce.data))
        } catch (error) {

        }
    }
}

export const deleteCustormerData = (pk) => {
    return async (dispatch) => {
        dispatch(deleteCustormerRequest);
        try {
            const responce = axios.delete(`${process.env.REACT_APP_BACKEND_URL}/companies/delete/${pk}/`);
            dispatch(deleteCustormersSuccess(pk));
        } catch (error) {
            dispatch(deleteCustormersFailure);
        }
    }
}



